<template>
  <section>
    <ReadyAnswerSearch/>
    <ReadyAnswerItem :readyAnswer="{}"/>
    <ReadyAnswerItem
        v-for="readyAnswer in _readyAnswers"
        :key="readyAnswer._id"
        :readyAnswer="readyAnswer"
    />
  </section>
</template>
<script>
import ReadyAnswerSearch from "@/views/question/readyanswer/ReadyAnswerSearch.vue";
import ReadyAnswerItem from "@/views/question/readyanswer/ReadyAnswerItem.vue";
import {mapGetters} from "vuex";

export default {
  name: 'ReadyAnswerHome',
  components: {ReadyAnswerSearch, ReadyAnswerItem},
  computed: {
    ...mapGetters('readyAnswer', ['_readyAnswers']),
  },
  created() {
    this.$store.dispatch('readyAnswer/getAll')
  }
}
</script>