<template>
  <b-card class="m-0">
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col cols="1" class="m-0 p-0">
          <b-form-input disabled type="text" size="sm" v-model="readyAnswer.id" placeholder="Sıra No"/>
        </b-col>
        <b-col cols="3" class="m-0 p-0">
          <b-form-input :disabled="!isEdit" type="text" size="sm" v-model="readyAnswer.title" placeholder="Başlık"/>
        </b-col>
        <b-col cols="7" class="m-0 p-0">
          <b-form-input :disabled="!isEdit" type="text" size="sm" v-model="readyAnswer.content" placeholder="Cevap"/>
        </b-col>
        <b-col cols="1" class="m-0 p-0">
          <b-button-group size="sm">
            <b-button variant="primary" v-if="!isEdit" @click="isEdit=true">
              <feather-icon icon="EditIcon"></feather-icon>
            </b-button>
            <b-button variant="success" v-else @click="save">
              <feather-icon icon="SaveIcon"></feather-icon>
            </b-button>
            <b-button v-if="readyAnswer.id" variant="danger" @click="destroy">
              <feather-icon icon="XCircleIcon"></feather-icon>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: 'ReadyAnswerItem',
  props: {
    readyAnswer: {type: Object, required: true},
  },
  data: () => ({
    isEdit: false,
    show: false,
  }),
  methods: {
    destroy() {
      this.show = true;
      this.$swal({
        title: "Silmek istediğiniz emin misiniz?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sil",
        cancelButtonText: 'Vazgeç'
      })
          .then((result) => {
            if (result.isConfirmed) {
              this.$store.dispatch('readyAnswer/destroyReadyAnswer', this.readyAnswer.id)
                  .then(res => {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Silindi',
                        icon: 'XCircleIcon',
                        variant: 'danger'
                      }
                    })
                  })
                  .finally(() => {
                    this.show = false;
                  })
            }
          })
    },
    save() {
      this.show = true;
      this.$swal({
        title: "Kaydetmek istediğiniz emin misiniz?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Kaydet",
        cancelButtonText: 'Vazgeç'
      })
          .then((result) => {
            if (result.isConfirmed) {
              if (this.readyAnswer.id) {
                console.log(this.readyAnswer.id)
                this.$store.dispatch('readyAnswer/updateReadyAnswer', this.readyAnswer)
                    .then(res => {
                      if (res.content) {
                        this.isEdit = false
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Düzenleme Başarılı',
                            icon: 'success',
                            variant: 'success'
                          }
                        })
                      }
                    })
                    .finally(() => {
                      this.show = false;
                    })
              } else {
                this.$store.dispatch('readyAnswer/storeReadyAnswer', this.readyAnswer)
                    .then(res => {
                      console.log(res)
                      if (res.status === 'success') {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Kayıt Başarılı',
                            icon: 'success',
                            variant: 'success'
                          }
                        })
                      }
                    })
                    .finally(() => {
                      this.show = false;
                    })
              }
            }
          })
    }
  }
}
</script>