<template>
  <b-card>
    <b-overlay :show="show" rounded="sm">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="11">
          </b-col>
          <b-col cols="1">
            <b-button-group size="sm">
              <b-button variant="primary" type="submit" @click="getAll" title="Getir">
                <feather-icon icon="FilterIcon"></feather-icon>
              </b-button>
              <b-button variant="danger" type="reset" title="Temizle">
                <feather-icon icon="XCircleIcon"></feather-icon>
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'ReadyAnswerSearch',
  components: {
    vSelect,
  },
  data: () => ({
    show: false,
  }),
  computed: {},
  methods: {
    getAll() {
      this.$store.dispatch('readyAnswer/getAll')
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>